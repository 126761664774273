'use client';

import * as ToggleGroup from '@radix-ui/react-toggle-group';
import { useEffect, useState } from 'react';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import styles from './color-mode-toggle.module.css';

export type ColorModes = 'theme-light' | 'dark' | 'system';

export function ColorModeToggle() {
  const [theme, setThemeState] = useState<ColorModes>('theme-light');

  useEffect(() => {
    const isDarkMode = document.documentElement.classList.contains('dark');
    setThemeState(isDarkMode ? 'dark' : 'theme-light');
  }, []);

  useEffect(() => {
    const isDark =
      theme === 'dark' || (theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches);
    document.documentElement.classList[isDark ? 'add' : 'remove']('dark');
  }, [theme]);

  return (
    <ToggleGroup.Root
      type="single"
      value={theme}
      className={styles.root}
      onValueChange={(theme: ColorModes) => setThemeState(theme)}
    >
      <ToggleGroup.Item className={styles.item} value="theme-light" aria-label="Light theme">
        <MdOutlineLightMode className="h-4 w-4" />
      </ToggleGroup.Item>
      <ToggleGroup.Item className={styles.item} value="dark" aria-label="Dark theme">
        <MdOutlineDarkMode />
      </ToggleGroup.Item>
    </ToggleGroup.Root>
  );
}
